import React, {createRef, useCallback, useEffect, useState} from 'react';
import {Box, Divider, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import TranslationsInput from "../../translations/TranslationsInput";
import {Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {
    deepCopy,
    getImageUploadOriginalLink, helperCatchErrors,
    inputOnlyInteger,
    selectIconComponent,
    textareaOutput
} from "../../../helper/Helper";
import MaterialButton from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ImageUpload from "../../imageUpload/ImageUpload";
import Notifications from "../../notifications/Notifications";
import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import config from "../../../config/config";

const newCell = () => ({
    cs: 1,
    rs: 1,
    value: '',
    align: 'flex-start',
    valign: 'baseline',
    alignImage: 'left',
    size: '',
    imageRef: createRef(),
    imageUpload: false,
    image: '',
    imageOriginal: '',
    imageMaxWidth: '',
});

const TableElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);

    const prepareContent = () => {
        if (props.content.length) {
            return props.content.map(row => {
                return row.map(cell => {
                    cell.imageRef = createRef();
                    cell.imageUpload = false;

                    return cell;
                })
            });
        }

        return [];
    }

    const [content, setContent] = useState(prepareContent());
    const [imageUploading, setImageUploading] = useState(false);
    const [optionCollapse, setOptionCollapse] = useState({});

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    const initSave = () => {
        resetNotifications();

        content.forEach((row) => {
            row.forEach(cell => {
                cell.imageRef.current.sendFile();
            })
        });

        setImageUploading(true);
    }

    const isEveryImageUploaded = useCallback(() => {
        let found = false;

        content.forEach(row => {
            if (row.findIndex(cell => cell.imageUpload === false) !== -1) {
                found = true;
            }
        });

        return !found;
    }, [content]);

    const saveModal = useCallback(() => {
        const data = {
            content,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, content, translations]);

    useEffect(() => {
        if (imageUploading && isEveryImageUploaded()) {
            setImageUploading(false);

            saveModal();
        }
    }, [imageUploading, isEveryImageUploaded, saveModal]);

    const setCellContent = (row, col, index, value, isReturn = false) => {
        const newContent = deepCopy(content);

        newContent[row][col][index] = value;

        if (isReturn) {
            return newContent;
        }

        setContent(newContent);
    };

    const setCellContentDirect = (row, col, index, value) => {
        setContent(prev => {
            const newContent = deepCopy(prev);

            newContent[row][col][index] = value;

            return newContent;
        })
    }

    const setTranslationCell = (row, col, value) => {
        const newTranslations = deepCopy(translations);

        newTranslations[row][col] = value;

        setTranslations(newTranslations);
    };

    const addContentRow = () => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);
        const newTranslationsRowContent = [];
        const newRowContent = [];

        for (let i = 0; i < content[0].length; i++) {
            newTranslationsRowContent.push([]);
        }

        newTranslations.push(newTranslationsRowContent);

        for (let i = 0; i < content[0].length; i++) {
            newRowContent.push(newCell());
        }

        newContent.push(newRowContent);

        setTranslations(newTranslations);
        setContent(newContent);
    };

    const addCol = () => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);

        newTranslations.forEach(row => {
            row.push([]);
        });

        newContent.forEach(row => {
            row.push(newCell());
        });

        setTranslations(newTranslations);
        setContent(newContent);
    };

    const deleteRow = (row) => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);

        newTranslations.splice(row, 1);
        newContent.splice(row, 1);

        setTranslations(newTranslations);
        setContent(newContent);
    };

    const deleteCol = (colIndex) => {
        const newTranslations = deepCopy(translations);
        const newContent = deepCopy(content);

        newTranslations.forEach(translation => {
            translation.splice(colIndex, 1);
        })

        newContent.forEach(translation => {
            translation.splice(colIndex, 1);
        })

        setTranslations(newTranslations);
        setContent(newContent);
    }

    const deleteLastCell = (rowIndex, contentParam = null) => {
        const newTranslations = deepCopy(translations);
        const newContent = contentParam || deepCopy(content);

        newTranslations[rowIndex].splice(newTranslations[rowIndex].length -1, 1);
        newContent[rowIndex].splice(newContent[rowIndex].length - 1, 1);

        setTranslations(newTranslations);
        setContent(newContent);
    }

    const addCell = (rowIndex, contentParam = null) => {
        const newTranslations = deepCopy(translations);
        const newContent = contentParam || deepCopy(content);

        newTranslations[rowIndex].push([]);
        newContent[rowIndex].push(newCell());

        setTranslations(newTranslations);
        setContent(newContent);
    }

    const increaseRowSpan = (rowIndex, colIndex) => {
        const rowSpan = content[rowIndex][colIndex]['rs'];

        if (rowIndex + rowSpan === content.length) {
            return;
        }

        const newContent = setCellContent(rowIndex, colIndex, 'rs', rowSpan + 1, true);

        deleteLastCell(rowIndex + rowSpan, newContent);
    }

    const decreaseRowSpan = (rowIndex, colIndex) => {
        const rowSpan = content[rowIndex][colIndex]['rs'];

        if (rowSpan === 1) {
            return;
        }

        const newContent = setCellContent(rowIndex, colIndex, 'rs', rowSpan - 1, true);

        addCell(rowIndex + rowSpan - 1, newContent);
    }

    const deleteCell = (rowIndex, colIndex, contentParam = null) => {
        const newTranslations = deepCopy(translations);
        const newContent = contentParam || deepCopy(content);

        newTranslations[rowIndex].splice(colIndex, 1);
        newContent[rowIndex].splice(colIndex, 1);

        setTranslations(newTranslations);
        setContent(newContent);
    }

    const increaseColSpan = (rowIndex, colIndex) => {
        const colSpan = content[rowIndex][colIndex]['cs'];
        const nextCellColIndex = colIndex + 1;

        if (nextCellColIndex === content[rowIndex].length) {
            return;
        }

        const newContent = setCellContent(rowIndex, colIndex, 'cs', colSpan + 1, true);
        const nextCellColSpan = content[rowIndex][nextCellColIndex]['cs'];

        if (nextCellColSpan > 1) {
            newContent[rowIndex][nextCellColIndex]['cs'] = nextCellColSpan - 1;

            setContent(newContent);
        } else {
            deleteCell(rowIndex, nextCellColIndex, newContent);
        }

    }

    const decreaseColSpan = (rowIndex, colIndex) => {
        const colSpan = content[rowIndex][colIndex]['cs'];

        if (colSpan === 1) {
            return;
        }

        const newContent = setCellContent(rowIndex, colIndex, 'cs', colSpan - 1, true);

        addCell(rowIndex, newContent);
    }

    const cellConfigs = (rowIndex, colIndex, cell, header = false) => {
        const checked = optionCollapse?.[rowIndex]?.[colIndex] || false;

        return (
            <Box mb={2}>
                <FormControlLabel
                    control={<Switch checked={checked} onChange={() => setOptionCollapse({
                        ...optionCollapse,
                        [rowIndex]: {
                            ...optionCollapse[rowIndex],
                            [colIndex]: !checked
                        }
                    })} />}
                    label={GlobalTransIntl('show_options', intl)}
                />
                <Collapse in={checked}>
                    {
                        (!header && (rowIndex + 1 !== content.length)) &&
                        <>
                            <IconButton
                                onClick={() => increaseRowSpan(rowIndex, colIndex)}
                                title={GlobalTransIntl('increase', intl)}
                            >
                                <FontAwesomeIcon icon={faPlusCircle} size={"xs"}/>
                            </IconButton>
                            {GlobalTransIntl('row', intl)}
                            <IconButton
                                onClick={() => decreaseRowSpan(rowIndex, colIndex)}
                                title={GlobalTransIntl('decrease', intl)}
                            >
                                <FontAwesomeIcon icon={faMinusCircle} size={"xs"}/>
                            </IconButton>
                        </>
                    }
                    <Box>
                        <IconButton
                            onClick={() => increaseColSpan(rowIndex, colIndex)}
                            title={GlobalTransIntl('increase', intl)}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size={"xs"}/>
                        </IconButton>
                        {GlobalTransIntl('column', intl)}
                        <IconButton
                            onClick={() => decreaseColSpan(rowIndex, colIndex)}
                            title={GlobalTransIntl('decrease', intl)}
                        >
                            <FontAwesomeIcon icon={faMinusCircle} size={"xs"}/>
                        </IconButton>
                    </Box>
                    <Box mb={2}>
                        <FormControl variant={'outlined'} required>
                            <InputLabel>
                                {GlobalTransIntl('align_text', intl)}
                            </InputLabel>
                            <Select
                                value={cell.align}
                                label={GlobalTransIntl('align_text', intl)}
                                onChange={(event) => setCellContent(rowIndex, colIndex, 'align', event.target.value)}
                                IconComponent={selectIconComponent}
                            >
                                <MenuItem value={'flex-start'}>
                                    {GlobalTransIntl('left', intl)}
                                </MenuItem>
                                <MenuItem value={'center'}>
                                    {GlobalTransIntl('center', intl)}
                                </MenuItem>
                                <MenuItem value={'flex-end'}>
                                    {GlobalTransIntl('right', intl)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl variant={'outlined'} required>
                            <InputLabel>
                                {GlobalTransIntl('vertical_align_text', intl)}
                            </InputLabel>
                            <Select
                                value={cell.valign}
                                label={GlobalTransIntl('vertical_align_text', intl)}
                                onChange={(event) => setCellContent(rowIndex, colIndex, 'valign', event.target.value)}
                                IconComponent={selectIconComponent}
                            >
                                <MenuItem value={'baseline'}>
                                    {GlobalTransIntl('default', intl)}
                                </MenuItem>
                                <MenuItem value={'top'}>
                                    {GlobalTransIntl('top', intl)}
                                </MenuItem>
                                <MenuItem value={'middle'}>
                                    {GlobalTransIntl('middle', intl)}
                                </MenuItem>
                                <MenuItem value={'bottom'}>
                                    {GlobalTransIntl('bottom', intl)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <TextField label={GlobalTransIntl('size', intl)}
                                   autoComplete={'off'}
                                   variant="outlined"
                                   onChange={(e) => {
                                       inputOnlyInteger(e, (value) => setCellContent(rowIndex, colIndex, 'size', parseInt(value)));
                                   }}
                                   value={cell.size}
                        />
                    </Box>
                    <Box mt={2}>
                        <ImageUpload
                            ref={cell.imageRef}
                            setImageIRI={(value) => {
                                setCellContentDirect(rowIndex, colIndex, 'image', value);
                                setCellContentDirect(rowIndex, colIndex, 'imageOriginal', value);
                            }}
                            showError={showError}
                            catchErrors={catchErrors}
                            setUploadStatus={(value) => setCellContentDirect(rowIndex, colIndex, 'imageUpload', value)}
                            originalIRI={cell.imageOriginal?.['@id']}
                            returnType={'object'}
                        />
                        {getImageUploadOriginalLink(cell.imageOriginal)}
                    </Box>
                    <Box mt={2}>
                        <FormControl variant={'outlined'} required>
                            <InputLabel>
                                {GlobalTransIntl('align_image', intl)}
                            </InputLabel>
                            <Select
                                value={cell.alignImage}
                                label={GlobalTransIntl('align_image', intl)}
                                onChange={(event) => setCellContent(rowIndex, colIndex, 'alignImage', event.target.value)}
                                IconComponent={selectIconComponent}
                            >
                                <MenuItem value={'left'}>
                                    {GlobalTransIntl('left', intl)}
                                </MenuItem>
                                <MenuItem value={'right'}>
                                    {GlobalTransIntl('right', intl)}
                                </MenuItem>
                                <MenuItem value={'top'}>
                                    {GlobalTransIntl('top', intl)}
                                </MenuItem>
                                <MenuItem value={'bottom'}>
                                    {GlobalTransIntl('bottom', intl)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <TextField label={GlobalTransIntl('image_max_width', intl)}
                                   autoComplete={'off'}
                                   variant="outlined"
                                   onChange={(e) => {
                                       inputOnlyInteger(e, (value) => setCellContent(rowIndex, colIndex, 'imageMaxWidth', parseInt(value) || ''));
                                   }}
                                   InputProps={{
                                       endAdornment: 'px',
                                   }}
                                   value={cell.imageMaxWidth}
                        />
                    </Box>
                    <Box mt={2}>
                        <Divider/>
                    </Box>
                </Collapse>
            </Box>
        );
    };

    const getDeleteColRow = (row) => {
        const content = [];

        for (let i = 0; i < row.length; i++) {
            content.push(<TableCell>
                <IconButton
                    onClick={() => {
                        deleteCol(i)
                    }}
                    aria-label={GlobalTransIntl('title_delete', intl)}
                    title={GlobalTransIntl('title_delete', intl)}
                >
                    <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                </IconButton>
            </TableCell>);
        }

        return content;
    }

    const modalBody = (
        <Box mb={2}>
            <Notifications
                success={notificationSuccess}
                error={notificationError}
                errorMessage={errorMessage}
            />
            <Box>
                Bold: [b]Text[/b]
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                content[0].map((cell, colIndex) => (
                                    <TableCell key={colIndex} colSpan={cell.cs} rowSpan={cell.rs}>
                                        {cellConfigs(0, colIndex, cell, true)}
                                        <TranslationsInput
                                            field={'content'}
                                            defaultValue={cell.value}
                                            setDefaultValue={(value) => setCellContent(0, colIndex, 'value', value)}
                                            translations={translations[0][colIndex]}
                                            language={translationLanguage}
                                            label={GlobalTransIntl('text', intl)}
                                            setTranslations={(value) => setTranslationCell(0, colIndex, value)}
                                            attributes={{
                                                autoComplete: 'off',
                                                multiline: true,
                                            }}
                                        />
                                    </TableCell>
                                ))
                            }
                            <TableCell>
                                <MaterialButton
                                    variant="contained"
                                    color="default"
                                    className={''}
                                    startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                                    onClick={addCol}
                                    aria-label={GlobalTransIntl('add', intl)}
                                    title={GlobalTransIntl('add', intl)}
                                >
                                    {GlobalTransIntl('add', intl)}
                                </MaterialButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            content.map((row, rowIndex) => (
                                (rowIndex > 0) &&
                                <React.Fragment key={rowIndex}>
                                    <TableRow>
                                        {
                                            row.map((cell, colIndex) => (
                                                <TableCell scope="row" key={colIndex} colSpan={cell.cs} rowSpan={cell.rs}>
                                                    {cellConfigs(rowIndex, colIndex, cell)}
                                                    <TranslationsInput
                                                        field={'content'}
                                                        defaultValue={cell.value}
                                                        setDefaultValue={(value) => setCellContent(rowIndex, colIndex, 'value', value)}
                                                        translations={translations[rowIndex][colIndex]}
                                                        language={translationLanguage}
                                                        label={GlobalTransIntl('text', intl)}
                                                        setTranslations={(value) => setTranslationCell(rowIndex, colIndex, value)}
                                                        attributes={{
                                                            autoComplete: 'off',
                                                            multiline: true,
                                                        }}
                                                    />
                                                </TableCell>
                                            ))
                                        }
                                        {
                                            (content.length > 2) &&
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        deleteRow(rowIndex);
                                                    }}
                                                    aria-label={GlobalTransIntl('title_delete', intl)}
                                                    title={GlobalTransIntl('title_delete', intl)}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                                                </IconButton>
                                            </TableCell>
                                        }
                                    </TableRow>
                                    <TableRow>
                                        {
                                            (rowIndex === (content.length - 1) && row.length > 1) &&
                                            getDeleteColRow(row)
                                        }
                                    </TableRow>
                                </React.Fragment>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2}>
                <MaterialButton
                    variant="contained"
                    color="default"
                    className={''}
                    startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                    onClick={addContentRow}
                    aria-label={GlobalTransIntl('add', intl)}
                    title={GlobalTransIntl('add', intl)}
                >
                    {GlobalTransIntl('add', intl)}
                </MaterialButton>
            </Box>
        </Box>
    );

    const replaceTextStyling = (value) => {
        return value
            .replaceAll("[b]", "<b>")
            .replaceAll("[/b]", "</b>");
    }

    const getImage = (cell, key) => {
        return (
            <Box sx={{maxWidth: cell.imageMaxWidth, display: 'inline-block'}} key={ key + '_image'}>
                <img
                    src={config.apiHost + cell.imageOriginal.contentUrl}
                    alt={''}
                    className={'img-fluid'}
                />
            </Box>
        );
    }

    const getCellContentWithImage = (cell, rowIndex, colIndex) => {
        const key = rowIndex + '_' + colIndex;

        if (!cell.imageOriginal) {
            return (
                <Grid container spacing={2} justifyContent={cell.align}>
                    <Grid item>
                        {textareaOutput(replaceTextStyling(cell.value || ''))}
                    </Grid>
                </Grid>
            );
        }

        if (cell.alignImage === 'top' || cell.alignImage === 'bottom') {
            return (
                <>
                    {
                        (cell.alignImage === 'top') &&
                        <Grid container spacing={2} justifyContent={cell.align}>
                            <Grid item>
                                {getImage(cell, key)}
                            </Grid>
                        </Grid>
                    }
                    {
                        <Grid container spacing={2} justifyContent={cell.align}>
                            <Grid item>
                                {textareaOutput(replaceTextStyling(cell.value || ''))}
                            </Grid>
                        </Grid>
                    }
                    {
                        (cell.alignImage === 'bottom') &&
                        <Grid container spacing={2} justifyContent={cell.align}>
                            <Grid item>
                                {getImage(cell, key)}
                            </Grid>
                        </Grid>
                    }
                </>
            );
        }

        return (
            <Grid container spacing={2} justifyContent={cell.align}>
                <>
                    {
                        (cell.alignImage === 'left') &&
                        <Grid item>
                            {getImage(cell, key)}
                        </Grid>
                    }
                    <Grid item>
                        {textareaOutput(replaceTextStyling(cell.value || ''))}
                    </Grid>
                    {
                        (cell.alignImage === 'right') &&
                        <Grid item>
                            {getImage(cell, key)}
                        </Grid>
                    }
                </>
            </Grid>
        );
    }

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={initSave}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
                modalSize={'xl'}
            >
                {modalBody}
            </ModuleElementActions>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                props.content[0].map((cell, colIndex) => (
                                    <TableCell key={colIndex} colSpan={cell.cs} rowSpan={cell.rs} sx={{verticalAlign: cell.valign, fontSize: cell.size}}>
                                        {getCellContentWithImage(cell, 0, colIndex)}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.content.map((row, rowIndex) => (
                            (rowIndex > 0) &&
                            <TableRow key={rowIndex}>
                                {
                                    row.map((cell, colIndex) => (
                                        <TableCell scope="row" key={colIndex} colSpan={cell.cs} rowSpan={cell.rs} sx={{verticalAlign: cell.valign}}>
                                            {getCellContentWithImage(cell, rowIndex, colIndex)}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

TableElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const TableElementConfig = {
    title: 'table',
    content: [[newCell()], [newCell()]],
    translations: [[[]], [[]]],
}

export default TableElement;