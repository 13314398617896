import React, {createRef, useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import ImageUpload from "../imageUpload/ImageUpload";
import {Box, Button, IconButton, Link, Modal} from "@material-ui/core";
import config from "../../config/config";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";
import axios from "axios";
import {useStore} from "../../store/useStore";
import Divider from "@material-ui/core/Divider";
import TranslationsInput from "../translations/TranslationsInput";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import {deepCopy, helperCatchErrors, scrollToTop} from "../../helper/Helper";
import Notifications from "../notifications/Notifications";

const Ocs = (props) => {
    const {state} = useStore();
    const intl = useIntl();
    const referenceRef = useRef();
    const [referenceSheetUpload, setReferenceSheetUpload] = useState(false);
    const [cancelToken] = useState(axios.CancelToken.source());

    const [translationLanguage, setTranslationLanguage] = useState('');
    const [modalOcsOpen, setModalOcsOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [removeAmount, setRemoveAmount] = useState(0);
    const [removeIndex, setRemoveIndex] = useState(-1);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const prepareUploadRefs = useCallback(() => {
        const references = props.editReference ? props.references[props.editReference] : {};
        references.refs = {};

        if (state.languages.length) {
            state.languages.forEach(language => {
                references.refs[language['@id']] = {
                    ref: createRef(),
                    uploaded: false
                };
            });
        }

        return references;
    }, [props.editReference, props.references, state.languages]);

    const [localReference, setLocalReference] = useState(prepareUploadRefs());

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        scrollToTop();
        setNotificationError(true);
    }

    const addReferenceSheet = useCallback((referenceSheet) => {
        setLocalReference(prev => ({
            ...prev,
            id: referenceSheet['@id'],
            contentUrl: referenceSheet.contentUrl
        }));
    }, []);

    const addReferenceSheetTranslation = useCallback((languageIRI, referenceSheet) => {
        setLocalReference(prev => {
            const newPrev = deepCopy(prev);
            const findTranslation = newPrev.translations.find(translation => translation.language === languageIRI);

            if (findTranslation) {
                findTranslation['id'] = referenceSheet['@id'];
                findTranslation['contentUrl'] = referenceSheet.contentUrl;
            }

            return newPrev;
        });
    }, []);

    useEffect(() => {
        if (isRemoving && removeIndex !== -1 && removeAmount === (props.references[removeIndex]?.translations ? Object.keys(props.references[removeIndex].translations).length + 1 : 1)) {
            setIsRemoving(false);

            const newReferences = [...props.references];
            newReferences.splice(removeIndex, 1);
            props.setReferences(newReferences);

            setRemoveAmount(0);
            setRemoveIndex(-1);
        }
    }, [isRemoving, props, removeAmount, removeIndex]);

    const removeReference = (index) => {
        setRemoveIndex(index);
        setRemoveAmount(props.references[index]?.translations ? Object.keys(props.references[index].translations).length + 1 : 1);
        setIsRemoving(true);

        axios.delete(config.apiHost + props.references[index].id, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'Content-Type': 'multipart/form-data'}
        }))
            .then(() => {
                setRemoveAmount(prev => prev++);
            })
            .catch(error => {
                if (error.response.status === 404) {
                    setRemoveAmount(prev => prev++);
                }
            });

        if (props.references[index]?.translations) {
            Object.keys(props.references[index].translations).forEach(translationIndex => {
                if (props.references[index].translations[translationIndex]?.id) {
                    axios.delete(config.apiHost + props.references[index].translations[translationIndex].id, config.axiosConfig(state.token, {
                        cancelToken: cancelToken.token,
                        headers: {'Content-Type': 'multipart/form-data'}
                    }))
                        .then(() => {
                            setRemoveAmount(prev => prev++);
                        })
                        .catch(error => {
                            if (error.response.status === 404) {
                                setRemoveAmount(prev => prev++);
                            }
                        });
                } else {
                    setRemoveAmount(prev => prev++);
                }
            });
        }
    }

    const uploadReferenceSheet = () => {
        if (!referenceRef.current.hasFile()) {
            showError(GlobalTransIntl('missing_file', intl));
            return;
        }

        referenceRef.current.sendFile();

        Object.keys(localReference.refs).forEach(refKey => {
            localReference.refs[refKey].ref.current.sendFile();
        });

        setIsUploading(true);
    }

    const saveReference = useCallback(() => {
        const newReferences = [...props.references];
        delete localReference.refs;

        if (props.editReference) {
            newReferences.splice(props.editReference, 1);
            newReferences.splice(props.editReference, 0, localReference);
        } else {
            newReferences.push(localReference);
        }

        props.setReferences(newReferences);

        handleClose();
        setLocalReference(prepareUploadRefs());
    }, [localReference, prepareUploadRefs, props]);

    useEffect(() => {
        if (isUploading && referenceSheetUpload && !Object.keys(localReference.refs).find(refKey => localReference.refs[refKey].uploaded === false)) {
            setIsUploading(false);
            setReferenceSheetUpload(false);
            saveReference();
        }
    }, [isUploading, localReference, props, referenceSheetUpload, saveReference]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken])

    const handleClose = () => {
        setModalOcsOpen(false);
        setTranslationLanguage('');
    };

    const handleOpen = () => {
        setModalOcsOpen(true);
    }

    const setReferenceField = (field, value) => {
        setLocalReference(prev => ({
            ...prev,
            [field]: value
        }));
    }

    const updateUploadRef = (value, languageIRI) => {
        setLocalReference(prev => {
            const newPrev = deepCopy(prev);

            newPrev.refs[languageIRI].uploaded = value;

            return newPrev;
        });
    }

    return (
        <div>
            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={handleOpen}>Upload files</Button>
            </Box>
            <span>{GlobalTransIntl('references', intl)}</span>
            {
                props.references.map((reference, index) => (
                    <div key={index}>
                        <IconButton onClick={() => removeReference(index)} title={GlobalTransIntl('delete', intl)}>
                            <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                        </IconButton>
                        <Link target={'_blank'} href={config.apiHost + reference.contentUrl}>
                            {reference.name}
                        </Link>
                    </div>
                ))
            }
            <Modal
                open={modalOcsOpen}
                onClose={handleClose}
                className={'MuiModal-root'}
            >
                <div className={'main-modal modal-size-lg'}>
                    <Notifications
                        error={notificationError}
                        errorMessage={errorMessage}
                    />
                    <TranslationLanguages selectedLanguage={translationLanguage} setLanguage={setTranslationLanguage}/>
                    <Box mt={4}>
                        <TranslationsInput
                            field={'name'}
                            defaultValue={localReference.name ?? ''}
                            setDefaultValue={(value) => setReferenceField('name', value)}
                            translations={localReference.translations ?? []}
                            language={translationLanguage}
                            label={GlobalTransIntl('name', intl)}
                            setTranslations={(value) => setReferenceField('translations', value)}
                            attributes={{
                                autoComplete: 'off'
                            }}
                        />
                    </Box>
                    <Box mt={2} display={!translationLanguage ? 'block' : 'none'}>
                        <ImageUpload
                            ref={referenceRef}
                            setImageIRI={addReferenceSheet}
                            showError={showError}
                            catchErrors={catchErrors}
                            setUploadStatus={setReferenceSheetUpload}
                            filetypes={['application/pdf']}
                            returnType={'object'}
                        />
                    </Box>
                    {
                        state.languages.map(language => (
                            <Box mt={2} key={language.id}
                                 display={translationLanguage && translationLanguage === language['@id'] ? 'block' : 'none'}>
                                <ImageUpload
                                    ref={localReference.refs[language['@id']].ref}
                                    setImageIRI={(file) => addReferenceSheetTranslation(language['@id'], file)}
                                    showError={showError}
                                    catchErrors={catchErrors}
                                    setUploadStatus={(value) => updateUploadRef(value, language['@id'])}
                                    filetypes={['application/pdf']}
                                    returnType={'object'}
                                />
                            </Box>
                        ))
                    }
                    <Box mt={2}>
                        <Divider/>
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="primary"
                                onClick={uploadReferenceSheet}>{GlobalTransIntl('button_send', intl)}</Button>
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

Ocs.propTypes = {
    references: PropTypes.array.isRequired,
    setReferences: PropTypes.func.isRequired,
    editReference: PropTypes.number,
}

export default Ocs;